import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'jamea-button',
  templateUrl: './jamea-button.component.html',
  styleUrls: ['./jamea-button.component.scss']
})
export class JameaButtonComponent implements OnInit {
  @Input() color: 'blue' | 'white' | 'orange' = 'blue';
  @Input() width;
  @Input() height;
  @Input() loading: boolean = false;
  @Input() disabled = false;

  constructor() { }

  ngOnInit(): void {
  }

}
