<div class="white-container center">
    <div class="main-div">
        <img src="assets/jamea-logo.svg" alt="Jamea Logo" class="logo" />
        <span *ngIf="error" class="error">{{ error }}</span>
        <div class="flex-column">
            <label for="code">Please enter your email:</label>
            <input (keyup.enter)="submit(code.value)" #code name="code" id="code">
        </div>
        <div class="button-container">
            <button class="button button-blue" (click)="submit(code.value)">Submit</button>
        </div>
    </div>
</div>