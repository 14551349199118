import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClassDetailsComponent } from './components/class-details/class-details.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { StudentDetailsComponent } from './components/student-details/student-details.component';
import { AuthenticationGuard } from './guards/authentication.guard';
import { ConfirmCodeComponent } from './components/confirm-code/confirm-code.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';


export const APP_ROUTES = {
  LOGIN: 'login',
  SIGN_UP: 'sign-up',
  CHANGE_PASSWORD: 'change-password',
  FORGOT_PASSWORD: 'forgot-password',
  STUDENT: 'student',
  COURSE: 'course',
  CONFIRM_CODE: 'confirm-code'
}

// define all routes here
const routes: Routes = [
  { path: APP_ROUTES.LOGIN, component: LoginComponent },
  { path: APP_ROUTES.SIGN_UP, component: SignUpComponent },
  { path: APP_ROUTES.CHANGE_PASSWORD, component: ChangePasswordComponent },
  { path: APP_ROUTES.FORGOT_PASSWORD, component: ForgotPasswordComponent },
  { path: APP_ROUTES.CONFIRM_CODE, component: ConfirmCodeComponent },
  { path: APP_ROUTES.STUDENT, component: StudentDetailsComponent, canActivate: [AuthenticationGuard] },
  { path: APP_ROUTES.COURSE, component: ClassDetailsComponent, canActivate: [AuthenticationGuard] },
  { path: '',   redirectTo: APP_ROUTES.LOGIN, pathMatch: 'full' },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
