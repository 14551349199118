import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { AppConfigService, TokenDetails } from 'src/app/services/app-config.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { RestApiService } from 'src/app/services/rest-api.service';
import { environment } from 'src/environments/environment';
import { SuccessDialogComponent } from '../shared/success-dialog/success-dialog.component';
import { APP_ROUTES } from 'src/app/app-routing.module';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  email = ''
  session: string;
  error = '';
  showPassword = false;
  showConfirmPassword = false;

  constructor(private restApi: RestApiService, private dialog: MatDialog, private router: Router, private config: AppConfigService, private ds: DataStoreService) {
    const email = (this.router.getCurrentNavigation()?.extras?.state as any)?.email;
    const session = (this.router.getCurrentNavigation()?.extras?.state as any)?.session;

    if (!email) {
      window.alert('Please login and try again');
      this.router.navigate([APP_ROUTES.LOGIN]);
    }

    this.email = email;
    this.session = session;
  }

  ngOnInit(): void {
  }

  openConfirmationDialog() {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      width: '530px',
      height: '150px',
      panelClass: 'dialog',
      data: { message: 'Sign up successful! Please login to continue.' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate([APP_ROUTES.LOGIN]);
      }
    });
  }

  submit(password: string, newPassword) {
    if (password === newPassword) {
      this.restApi.changePassword(this.email, password, this.session)
        .subscribe((res: TokenDetails) => {
          this.config.storeToken(res);
          this.config.getTokenFromStorage();
          this.router.navigate([APP_ROUTES.COURSE]);
        });
    }

    return;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
   }

   toggleShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
   }

}
