import { Component, OnInit } from '@angular/core';
import { SuccessDialogComponent } from '../shared/success-dialog/success-dialog.component';
import { RestApiService } from 'src/app/services/rest-api.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { APP_ROUTES } from 'src/app/app-routing.module';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  error = '';

  constructor(
    private restApi: RestApiService,
    private dialog: MatDialog,
    private router: Router,
    private config: AppConfigService,
    private ds: DataStoreService) { }

  ngOnInit(): void {}
  openConfirmationDialog(username: string) {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      width: '530px',
      height: '190px',
      panelClass: 'dialog',
      data: { message: 'Please check your email for a confirmation code' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate([APP_ROUTES.CONFIRM_CODE], { queryParams: { redirect: 'reset-password', username }});
      }
    });
  }

  submit(username: string) {
    const cognitoUser = this.config.getCognitoUserFromUsername(username);

    // call forgotPassword on cognitoUser
    cognitoUser.forgotPassword({
        onSuccess: result => {
            this.openConfirmationDialog(username);
        },
        onFailure: err => {
            alert(err);
        }
    });
    
  }

  resendCode() {
    this.config.cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
    });
  }

}
