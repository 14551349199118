import { AfterContentInit, AfterViewInit, Component, ContentChildren, ElementRef, Input, OnChanges, OnInit, QueryList, ViewChild, ViewContainerRef } from '@angular/core';
import { ExpandableCardComponent } from './expandable-card/expandable-card.component';
import { ExpandableCardsService } from 'src/app/services/expandable-cards.service';
import { ExpandableCardDirective } from './expandable-card.directive';

@Component({
  selector: 'expandable-cards',
  templateUrl: './expandable-cards.component.html',
  styleUrls: ['./expandable-cards.component.scss'],
  providers: [ExpandableCardsService]
})
export class ExpandableCardsComponent implements OnInit, AfterViewInit {
  @Input() activeComponents = [];

  @ContentChildren(ExpandableCardDirective) cards: QueryList<ExpandableCardDirective>;
  @ViewChild('vc', {read: ViewContainerRef}) vc: ViewContainerRef;

  activeComponent;

  constructor(public cardsService: ExpandableCardsService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.cardsService.activeCard$.subscribe(card => {
      this.activeComponent = this.cards.find(cardComponent => cardComponent.card === card);
      if (this.activeComponent) {
        this.vc.clear();
        this.vc.createEmbeddedView(this.activeComponent.componentRef);
      }
      
    })
  }

}
