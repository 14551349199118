<div class="main-content">
    <div class="flex-container-1">
        {{ userName}}
        <span *ngIf="config.isAdmin" class="admin-badge">Admin</span>
    </div>
    
    <div class="flex-container-2">
        <h2>{{ schoolName }}</h2>
        <h4>{{ date }}</h4>
    </div>
    
    <div class="flex-container-3">
        <a *ngIf="config.isAdmin">Settings</a>
        <button id="logout" class="button button-blue" (click)="onLogout()">Logout</button>
    </div>
</div>
