import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-student-marks',
  templateUrl: './student-marks.component.html',
  styleUrls: ['./student-marks.component.scss']
})
export class StudentMarksComponent {

  @Input() studentMarks: any;
  @Input() loadingMarks = false;
  @ViewChild('studentList') studentList;

  filterMode: string = 'percent';
  
  letterGrades = [
    { grade: 50, letter: "D-" },
    { grade: 53.33, letter: "D" },
    { grade: 56.67, letter: "D+" },
    { grade: 60, letter: "C-" },
    { grade: 63.33, letter: "C" },
    { grade: 66.67, letter: "C+" },
    { grade: 70, letter: "B-" },
    { grade: 73.33, letter: "B" },
    { grade: 76.67, letter: "B+" },
    { grade: 80, letter: "A-" },
    { grade: 85, letter: "A" },
    { grade: 90, letter: "A+" },
  ]


  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["studentMarks"]?.currentValue?.length) {
      this.switchMode(this.filterMode);
      const maxHeight = document.querySelector('app-assessment-list .flex-column')?.clientHeight - 60 || 400;
      // we get the height of the container before the student list is rendered
      // and then we set that to student list as soon as it renders
      setTimeout(() => {
        this.renderer.setStyle(this.studentList.nativeElement, 'max-height', maxHeight + 'px');
      })
    }
  }

  switchMode(mode: string) {
    this.filterMode = mode;

    this.studentMarks = this.studentMarks.map((student: any) => {
      let renderedMark;
      if (student.received_mark !== '-') {
        if (mode === 'percent') {
          renderedMark = ((student.received_mark / student.total_mark) * 100).toFixed(2) + "%";
        } else if (mode === 'letter') {
          const percentMark = (student.received_mark / student.total_mark) * 100;
          for (let i = 0; i < this.letterGrades.length; i++) {
            if (percentMark >= this.letterGrades[i].grade) {
              if (i === this.letterGrades.length - 1 || percentMark < this.letterGrades[i+1].grade) {
                renderedMark = this.letterGrades[i].letter;
                break;
              }
            }
          }
  
          if (!renderedMark) renderedMark = 'F';
        } else if (mode === 'fraction') {
          renderedMark = student.received_mark + ' / ' + student.total_mark;
        }
      } else {
        renderedMark = '-'
      }

      return {
        ...student,
        renderedMark
      }
    })
  }

}
