import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnChanges, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { RestApiService } from 'src/app/services/rest-api.service';
import * as dayjs from 'dayjs';

interface DialogData {
  editing: boolean;
  assessment: any;
  sectionId: string;
  delete?: boolean;
}

@Component({
  selector: 'app-assessment-dialog',
  templateUrl: './assessment-dialog.component.html',
  styleUrls: ['./assessment-dialog.component.scss']
})
export class AssessmentDialogComponent implements OnInit, OnChanges {

  assessmentDetailsForm!: FormGroup;
  editing = true;

  constructor(
    public dialogRef: MatDialogRef<AssessmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private restApi: RestApiService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.assessmentDetailsForm = new FormGroup({
      title: new FormControl(''),
      date: new FormControl(''),
      weight: new FormControl(''),
      category_name: new FormControl(''),
      total_mark: new FormControl(''),
    });
    this.editing = this.data.editing;

    this.assessmentDetailsForm.get('category_name').disable();

    if (this.data.assessment) {
      this.assessmentDetailsForm.patchValue(this.data.assessment);
    }
  }

  ngOnChanges() {
    if (this.data.assessment) {
      const date = dayjs(this.data.assessment.date).format('YYYY-MM-DD');
      this.data.assessment.date = date;
    }
  }

  ngAfterViewInit(): void {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onCreateAssessment() {
    const formValues = this.assessmentDetailsForm.value;
    formValues.date = dayjs(formValues.date).format()
    const request = {
      ...formValues,
      section_id: this.data.sectionId
    }
    this.restApi.createAssessment(request).subscribe(res => {
      this.dialogRef.close({refresh: true});
    })
  }

  onCreateAndInput() {
    const formValues = this.assessmentDetailsForm.value;
    const request = {
      ...formValues,
      section_id: this.data.sectionId
    }
    this.restApi.createAssessment(request).subscribe(res => {
      this.dialogRef.close({refresh: true});
    })
  }

  onEditAssessment() {
    const formValues = this.assessmentDetailsForm.value;
    const request = {
      ...formValues,
      section_id: +this.data.sectionId
    }
    this.restApi.editAssessment(this.data.assessment.id, request).subscribe(res => {
      this.dialogRef.close({refresh: true});
    })
  }

  onDeleteAssessment() {
    this.restApi.deleteAssessment(this.data.assessment.id).subscribe(res => {
      this.dialogRef.close({ refresh: true });
    })
  }

}
