<div class="white-container center">
  <div class="main-div">
    <img src="assets/jamea-logo.svg" alt="Jamea Logo" class="logo" />
    <form action="" [formGroup]="signUpForm">
      <div class="input">
        <!-- <label>First Name</label> -->
        <input
          #firstName
          type="text"
          placeholder="First Name"
          class=""
          formControlName="givenName"
        />
      </div>
      <div class="input">
        <!-- <label>Last Name</label> -->
        <input
          #lastName
          type="text"
          placeholder="Last Name"
          class=""
          formControlName="familyName"
        />
      </div>

      <div class="input">
        <div class="radio">
          <label for="male">Male</label>
          <input
            #genderVal
            id="male"
            name="gender"
            type="radio"
            placeholder="text"
            value="male"
            class=""
            formControlName="gender"
          />
        </div>
        <div class="radio">
          <label for="female">Female</label>
          <input
            #genderVal
            id="female"
            name="gender"
            type="radio"
            value="female"
            placeholder="text"
            class=""
            formControlName="gender"
          />
        </div>
      </div>

      <div class="input flex-column">
        <!-- <label>Email</label> -->
        <input
          type="text"
          placeholder="School Code"
          class=""
          formControlName="schoolCode"
        />
        <mat-icon *ngIf="correctSchoolCode" class="checkmark">check_circle</mat-icon>
        <mat-icon *ngIf="incorrectSchoolCode" class="incorrect">cancel</mat-icon>
        <mat-spinner *ngIf="codeCheckInProgress" diameter="25" color="#00A1E"></mat-spinner>
        <span *ngIf="incorrectSchoolCode" class="error">
          School code does not exist!
        </span>
      </div>

      <div *ngIf="correctSchoolCode" class="input flex-column">
        <!-- <label>Email</label> -->
        <input
          #emailAddress
          type="text"
          placeholder="Email"
          class=""
          formControlName="email"
        />
        <mat-icon *ngIf="emailExists" class="checkmark">check_circle</mat-icon>
        <mat-icon *ngIf="emailDoesNotExist" class="incorrect">cancel</mat-icon>
        <mat-spinner *ngIf="emailCheckInProgress" diameter="25" color="#00A1E"></mat-spinner>
        <span *ngIf="emailDoesNotExist" class="error">
          Email does not exist in system!
        </span>
      </div>

      

      <div class="input flex-column">
        <input
          #passwordVal
          [type]="showPassword ? 'text' : 'password'"
          placeholder="Password"
          class=""
          formControlName="password"
          (blur)="checkPasswordPattern()"
        />
        <mat-icon (click)="toggleShowPassword()">{{ showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        <span *ngIf="showPasswordError && !signUpForm.get('password').valid" class="error">
          Password must contain at least 1 uppercase, lowercase, number and special character
        </span>
      </div>
      

      <div class="input flex-column">
        <input
          #passwordVal
          [type]="showConfirmPassword ? 'text' : 'password'"
          placeholder="Confirm Password"
          class=""
          formControlName="confirmPassword"
        />
        <mat-icon (click)="toggleShowConfirmPassword()">{{ showConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        <span *ngIf="signUpForm.get('confirmPassword').touched && 
        signUpForm.get('password').value !== signUpForm.get('confirmPassword').value" class="error">
        Passwords must match!
      </span>
      </div>

      <div *ngIf="error" class="error-banner">{{ error }}</div>
      

      <div class="button-container">
        <button class="button button-blue" type="button"
          (click)="signUp()"
          [disabled]="signUpForm.invalid || disableForm">
          Sign Up
        </button>
      </div>
      <div class="link-container">
          <a
          class="link-button"
          routerLink="/login"
          >Log In</a
          >
        </div>
    </form>
  </div>
</div>