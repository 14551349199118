import { Component, Inject, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { RestApiService } from 'src/app/services/rest-api.service';
import { Assessment } from '../assessments.component';

interface DialogData {
  assessment: Assessment;
  editing?: false;
  studentMarks: {
    id: string,
    first_name: string,
    last_name: string,
    received_mark: number,
    total_mark: number,
    title: string,
    student_id: string
  }[];
}

@Component({
  selector: 'app-marks-dialog',
  templateUrl: './marks-dialog.component.html',
  styleUrls: ['./marks-dialog.component.scss']
})
export class MarksDialogComponent implements OnInit, OnDestroy {

  studentMarksForm!: FormGroup<any>;
  editing = true;
  destroy$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<MarksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private restApi: RestApiService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.studentMarksForm = new FormGroup({});

    this.data?.studentMarks.forEach(student => {
      this.studentMarksForm.addControl(student.student_id, new FormControl(student.received_mark));
    });

    this.updateMarksOnChange();
  }

  updateMarksOnChange() {
    this.studentMarksForm.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(formValues => {
      const formValArray = Object.entries(formValues);
      formValArray.forEach(control => {
        const student = this.data.studentMarks.find(student => student.student_id === control[0]);
        if (student) {
          student.received_mark = +control[1];
        }
      })
    })
  }

  generateFullName(student) {
    return student.first_name + ' ' + student.last_name
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  submitMarks() {
    const req = this.data.studentMarks.map(student => {
      return {
        student_id: student.student_id,
        assessment_id: this.data.assessment.id,
        received_mark: student.received_mark
      }
    });

    if (this.data.editing) {
      this.restApi.editAssessmentMarks(req).subscribe(res => {
        this.dialogRef.close({ refresh: true });
      })
    } else {
      this.restApi.createAssessmentMarks(req).subscribe(res => {
        this.dialogRef.close({ refresh: true });
      })
    }
  }

  goToPreviousMark(index) {
    if (index > 0) {
      const prevInputId = this.data.studentMarks[index - 1].student_id;
      const el: HTMLInputElement = this.el.nativeElement.querySelector('#student' + prevInputId);
      el.focus();
    }
  }

  goToNextMark(index) {
    if (index < this.data.studentMarks.length - 1) {
      const nextInput = this.data.studentMarks[index + 1].student_id;
      const el: HTMLInputElement = this.el.nativeElement.querySelector('#student' + nextInput);
      el.focus();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

}
