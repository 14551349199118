import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnChanges, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { RestApiService } from 'src/app/services/rest-api.service';
import * as dayjs from 'dayjs';

interface DialogData {
  type;
  schoolCode;
  startDate;
  endDate;
  studentIds;
  studentNames;
  finalComments;
}

@Component({
  selector: 'export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent implements OnInit {

  exportDetailsForm!: FormGroup;
  editing = true;

  assessmentsDetails = '';
  attendanceDetails = '';
  reportBeingGenerated = false;

  constructor(
    public dialogRef: MatDialogRef<ExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private restApi: RestApiService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.exportDetailsForm = new FormGroup({
      file_per_student: new FormControl(false),
      combined: new FormControl(false)
    });

    this.exportDetailsForm.get('combined').disable();
  }
  
  getStudentNames() {
    return this.data.studentNames;
  }

  onGenerate() {
    const req = {
      ...this.data,
      combined: this.exportDetailsForm.get('combined').value,
    }
    this.reportBeingGenerated = true;
    this.restApi.generateReport(req).subscribe((res) => {
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
