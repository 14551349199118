import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, filter, switchMap, combineLatest, take } from 'rxjs';
import { AttendanceStatus } from 'src/models/attendance';
import { Student } from 'src/models/student';
import { Assessment } from '../components/class-details/assessments/assessments.component';
import { Course } from 'src/models/courses';
import { DataStoreService } from './data-store.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  rootUrl = environment.rootUrl;

  constructor(private http: HttpClient, private ds: DataStoreService) {}

  getSchool() {
    return this.ds.schoolCode$.pipe(
      filter(schoolCode => !!schoolCode),
      switchMap(schoolCode => {
        const url = `${this.rootUrl}/school/${schoolCode}/1`;
    
        return this.http.get(url) as Observable<any>;
      })
    )
  }

  getStudentList(): Observable<Student[]> {
    return combineLatest([this.ds.teacherId$, this.ds.schoolCode$]).pipe(
      filter(([teacherId, schoolCode]) => !!teacherId && !!schoolCode),
      switchMap(([teacherId, schoolCode]) => {
        const url = `${this.rootUrl}/student/studentList/${schoolCode}/${teacherId}`;
        return this.http.get(url) as Observable<Student[]>;
      })
    )
  }

  getStudentListByActiveCourse() {
    return combineLatest([this.ds.activeCourse$, this.ds.schoolCode$]).pipe(
      filter(([course, schoolCode])=> !!schoolCode && !!course),
      switchMap(([course, schoolCode]) => { 
        const url = `${this.rootUrl}/student/studentListBySection/${schoolCode}/${course.id}`;
        return this.http.get(url) as Observable<Student[]>;    
      })
    );
  }

  getStudentListBySection(sectionId: string): Observable<Student[]> {
    return this.ds.schoolCode$.pipe(
      filter(schoolCode => !!schoolCode),
      switchMap(schoolCode => {
        const url = `${this.rootUrl}/student/studentListBySection/${schoolCode}/${sectionId}`;
        return this.http.get(url) as Observable<Student[]>;    
      })
    )

  }

  getCourseList(): Observable<Course[]> {
    return combineLatest([this.ds.teacherId$, this.ds.schoolCode$]).pipe(
      filter(([teacherId, schoolCode]) => !!teacherId && !!schoolCode),
      switchMap(([teacherId, schoolCode]) => {
        const url = `${this.rootUrl}/section/sectionList/${schoolCode}/${teacherId}`;

        return this.http.get(url) as Observable<Course[]>;
      })
    )
  }

  getAssessmentList() {
    return combineLatest([this.ds.activeCourse$, this.ds.schoolCode$]).pipe(
      filter(([course, schoolCode]) => !!course && !!schoolCode),
      take(1),
      switchMap(([course, schoolCode]) => {
        return this.http.get(this.rootUrl + `/assessment/assessmentList/${schoolCode}/${course.id}`) as Observable<Assessment[]>;
      })
    )
  }

  getAssessmentMarks(assessmentId: number) {
    return this.ds.schoolCode$.pipe(
      filter(schoolCode => !!schoolCode),
      switchMap(schoolCode => {
        return this.http.get(this.rootUrl + `/assessment/marks/${schoolCode}/${assessmentId}`);
      })
    )
  }

  createAssessment(req) {
    return this.ds.schoolCode$.pipe(
      filter(schoolCode => !!schoolCode),
      switchMap(schoolCode => {
        const url = `${this.rootUrl}/assessment/${schoolCode}`;
    
        return this.http.post(url, req)
      })
    )
  }

  editAssessment(assessmentId, req) {
    return this.ds.schoolCode$.pipe(
      filter(schoolCode => !!schoolCode),
      switchMap(schoolCode => {
        const url = `${this.rootUrl}/assessment/${schoolCode}/${assessmentId}`;
    
        return this.http.patch(url, req)
      })
    )
  }

  deleteAssessment(assessmentId) {
    return this.ds.schoolCode$.pipe(
      filter(schoolCode => !!schoolCode),
      switchMap(schoolCode => {
        const url = `${this.rootUrl}/assessment/${schoolCode}/${assessmentId}`;
    
        return this.http.delete(url);
      })
    )
  }

  createAssessmentMarks(request) {
    return this.ds.schoolCode$.pipe(
      filter(schoolCode => !!schoolCode),
      switchMap(schoolCode => {
        const url = `${this.rootUrl}/assessment_mark/${schoolCode}`;
    
        return this.http.post(url, request)
      })
    )
  }

  editAssessmentMarks(request) {
    return this.ds.schoolCode$.pipe(
      filter(schoolCode => !!schoolCode),
      switchMap(schoolCode => {
        const url = `${this.rootUrl}/assessment_mark/${schoolCode}`;
    
        return this.http.patch(url, request)
      })
    )
  }

  getAttendance(sectionId, startDate?, endDate?): Observable<AttendanceStatus[]> {
    return this.ds.schoolCode$.pipe(
      filter(schoolCode => !!schoolCode),
      take(1),
      switchMap(schoolCode => {
        const url = `${this.rootUrl}/attendance/${schoolCode}/${sectionId}`;
        if (startDate && endDate) {
          return this.http.get(url, { params: { startDate, endDate }}) as Observable<AttendanceStatus[]>;
        }

        return this.http.get(url) as Observable<AttendanceStatus[]>;

      })
    )
  }

  createAttendance(body) {
    return this.ds.schoolCode$.pipe(
      filter(schoolCode => !!schoolCode),
      take(1),
      switchMap(schoolCode => {
        const url = `${this.rootUrl}/attendance/${schoolCode}`;

        return this.http.post(url, body) as Observable<AttendanceStatus[]>;
      })
    )
  }

  updateAttendance(body) {
    return this.ds.schoolCode$.pipe(
      filter(schoolCode => !!schoolCode),
      take(1),
      switchMap(schoolCode => {
        const url = `${this.rootUrl}/attendance/${schoolCode}`;

        return this.http.patch(url, body) as Observable<AttendanceStatus[]>;
      })
    )
  }

  generateReport(req) {
    const url = `${this.rootUrl}/reports/fetchReportData/${req.type}`;
    return this.http.post(url, req) as Observable<any>;
  }

  validateSchoolCode(code: string) {
    const url = `${this.rootUrl}/validateSchoolCode/${code}`;
    return this.http.get(url, { responseType: 'text' });
  }

  checkForTeacherId(schoolCode: string, email: string) {
    const url = `${this.rootUrl}/teacher/getId/${schoolCode}/${email}`;
    return this.http.get(url);
  }

  login(email, password) {
    const url = `${this.rootUrl}/userAuth/login`;

    return this.http.post(url, {
      email,
      password,
    });
  }

  changePassword(email, password, sessionKey) {
    const url = `${this.rootUrl}/auth/changePassword`;

    return this.http.post(url, {
      email,
      password,
      session: sessionKey
    });
  }

  getPastReports() {
    return this.ds.schoolCode$.pipe(
      filter(schoolCode => !!schoolCode),
      switchMap(schoolCode => {
        const url = `${this.rootUrl}/reports/getPastReports/${schoolCode}`
        return this.http.get(url);
      })
    )
  }
}
