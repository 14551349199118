<div class="flex calendar-heading">
  <div class="empty-container">
    Today
  </div>
  <div class="days">
    <i class="material-icons date-toggle left-arrow disable-select" (click)="toggleDates('back')">arrow_back_ios</i>
    <div *ngFor="let weekday of dates; let i = index" class="flex-column" [class.today]="weekday.today">
      <span>{{ weekday.date }}</span>
      <span>{{ weekday.day }}</span> 
      <!-- <div class="dividing-line" [style.height]="containerHeight + 80 + 'px'" *ngIf="(i + 1) % 5 === 0"></div> -->
    </div>
    <mat-icon class="material-icons date-toggle right-arrow disable-select" [class.disabled]="today === endDate" (click)="toggleDates('forward')">arrow_forward_ios</mat-icon>
  </div>
</div>
<div  class="content scroll">
  <div class="student-list-container">
    <div class="student-list">
      <div class="flex">
        <div class="row" *ngFor="let student of todaysRecords; let i = index">
            <p class="student-name"
            [class.absent]="student.status === 'A'"
            [class.late]="student.status === 'L'"
            [class.excused]="student.status === 'E'"
            >{{ student.name }}</p>
            <button
            class="student-status absent"
            [class.selected]="student.status === 'A'"
            (click)="setStatus('A', i)">
                A
            </button>
            <button 
            class="student-status late" 
            [class.selected]="student.status === 'L'"
            (click)="setStatus('L', i)">
                L
            </button>
            <button 
            class="student-status excused" 
            [class.selected]="student.status === 'E'"
            (click)="setStatus('E', i)">
                E
            </button>
        </div>
    </div>
  </div>
  
  </div>
  <div #calendar class="calendar">
    <div *ngIf="!attendanceLoading && attendance?.length" class="flex">
      <div class="row" *ngFor="let student of attendance">
        <div class="student-status-container" *ngFor="let status of student.records">
          <div class="student-status"
            class="student-status"
            [class.absent]="status === 'A'"
            [class.late]="status === 'L'"
            [class.excused]="status === 'E'">
            {{ status !== 'NULL' && status !== 'P' ? status : '' }}
          </div>
        </div>
        </div>
    </div>
    <mat-spinner *ngIf="attendanceLoading" class="center" diameter="50"></mat-spinner>
  </div>
</div>
<div class="submit-container">
  <button class="button button-light-blue" [disabled]="submitInProgress" (click)="updateAttendance()">{{ submitInProgress ? 'Loading...' : 'Submit'}}</button>
</div>

