export interface AttendanceDataResponse {
    attendance_id: number;
    student_id: number;
    id: number;
    date: Date;
    status: string;
    note: string;
}

export interface AttendanceStatus {
   date: string;
   firstName: string;
   surname: string;
   status: string;
   student_id: string;
}

export interface AttendanceRecord {
    date: string;
    status: string;
    note?: string;
    icon?: string;
    activeDate?: boolean;
    attendanceId?: number;
  }
  
  export interface StudentAttendance {
    studentName: string;
    studentId: number;
    attendanceRecords: AttendanceRecord[];
  }

  export class MarkAttendanceRequest {
    id: number;
    student_id: number;
    date: string;
    status?: string;
    attendance_id?: number;

    constructor(courseId: number, studentId: number, date: string, status?: string, attendanceId?: number) {
      this.id = courseId;
      this.student_id = studentId;
      this.date = date;
      this.status = status && status !== 'none' ? status : 'present';
      if (attendanceId) this.attendance_id = attendanceId;
    }
  }

  export interface MarkAttendanceResponse {
    attendance_id: number;
    id: number;
    student_id: number;
    date: string;
    status: string;
  }