<div (window:resize)="onWindowResize()" (window:click)="clickEvent($event)"></div>

<div *ngIf="!showSidebar">
    <mat-icon class="menu" (click)="openMenu()">menu</mat-icon>
</div>

<div [class.hidden]="!showSidebar" [class.open]="menuOpen" (blur)="closeMenu()" #container class="container">
    <img src="assets/jamea-logo.svg" alt="Jamea Logo" class="logo" />
    <div class="section">
        <h2 class="subheading title-text">
            Classes
        </h2>
        <div *ngIf="courses" #classList class="list">
            <div class="subject" [ngClass]="{'active': course.id === activeCourseId}" *ngFor="let course of courses"  (click)="selectCourse(course.id)">
                {{ course.grade_year + course.section_name + " " + course.course_title }}
                <!-- <span>{{course.schedule[today]}}</span> -->
            </div>
        </div>
        <mat-spinner class="center" *ngIf="!coursesLoaded" diameter="40"></mat-spinner>
        <h4 *ngIf="coursesLoaded && (!courses || courses.length === 0)">You do not have any courses assigned.</h4>
    </div>
    <div class="section">
        <h2 class="subheading title-text">Students</h2>
        <div #studentList class="list">
            <p class="student" [class.first]="i === 0" *ngFor="let student of (ds.students$ | async); let i = index">
                {{ student.first_name }} {{ student.last_name }}
            </p>
        </div>
        <mat-spinner class="center" *ngIf="!studentsLoaded" diameter="40"></mat-spinner>
    </div>
</div>
