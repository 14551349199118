import { RestApiService } from "src/app/services/rest-api.service";
import { SuccessDialogComponent } from "../shared/success-dialog/success-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AppConfigService } from "src/app/services/app-config.service";
import { DataStoreService } from "src/app/services/data-store.service";
import { Component, OnInit } from "@angular/core";
import { APP_ROUTES } from "src/app/app-routing.module";
import { BehaviorSubject, interval, take, timer } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";


@Component({
  selector: 'confirm-code',
  templateUrl: './confirm-code.component.html',
  styleUrls: ['./confirm-code.component.scss']
})
export class ConfirmCodeComponent implements OnInit {

  error = '';
  resetPassword = false;
  username = '';
  code = '';
  disableResendButtonUntil$: BehaviorSubject<number> = new BehaviorSubject(0);

  codeResent = false;

  form: FormGroup;

  constructor(
    private restApi: RestApiService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private config: AppConfigService,
    private ds: DataStoreService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      confirmationCode: new FormControl('')
    })

    this.route.queryParams.pipe(
      take(1)
    ).subscribe(params => {
      if (params['redirect'] === 'reset-password') {
        this.resetPassword = true;
        this.username = params['username'];
      }

      if (!this.config.cognitoUser && !this.username) {
        this.userNotAuthenticatedDialog();
      }

      if (this.resetPassword) {
        this.form.addControl('password', new FormControl('',
        [
          Validators.required,
          Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
        ]))

        this.form.addControl('confirmPassword', new FormControl('', 
        [
          Validators.required,
          Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
        ]))
      }
    })


  }
  openConfirmationDialog() {
      const dialogRef = this.dialog.open(SuccessDialogComponent, {
        width: '530px',
        height: this.resetPassword ? '190px' : '150px',
        panelClass: 'dialog',
        data: { message: this.resetPassword ? 'Password updated! Please login using your new password.' : 'Sign up successful! Please login to continue.' },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate([APP_ROUTES.LOGIN]);
        }
      });
  }

  userNotAuthenticatedDialog() {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      width: '530px',
      height: '190px',
      panelClass: 'dialog',
      data: { message: 'Something went wrong. Please try logging in again.' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate([APP_ROUTES.LOGIN]);
      }
    });
  }

  submit() {
    const formValues = this.form.value;

    if (this.resetPassword) {
      const cognitoUser = this.config.getCognitoUserFromUsername(this.username);
      cognitoUser.confirmPassword(
        formValues.confirmationCode,
        formValues.password,
        {
          onSuccess: result => {
            this.openConfirmationDialog();
          },
          onFailure: err => {
            alert(err);
          }
        }
      )
    } else {
      // Check if cognito returns authenticated user after the confirmation
      this.config.cognitoUser.confirmRegistration(formValues.confirmationCode, false, (err, result) => {
        if (result) {
          this.openConfirmationDialog(); 
        }
        if (err) {
          this.error = 'The code entered is incorrect. Please try again.'
        }
      })
    }



    
  }

  resendCode() {
    this.codeResent = false;
    const cognitoUser = this.config.cognitoUser ? this.config.cognitoUser : this.config.getCognitoUserFromUsername(this.username);

    cognitoUser.resendConfirmationCode((err, result) => {
      if (result) {
        this.codeResent = true;
        this.disableResendTimer(30)
      } else if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
    });
  }

  disableResendTimer(count: number) {
    this.disableResendButtonUntil$.next(count);
    if (count > 0) {
      timer(1000).subscribe(() => this.disableResendTimer(count - 1));
    }
  }
}