<!-- <p *ngFor="let student of studentMarks">{{ student.first_name + ' ' + student.last_name }}</p> -->

<div class="marks-container">
    <div *ngIf="studentMarks && studentMarks.length">
        <div class="flex">
            <span class="assessment-title title-text">{{ studentMarks[0].title }}</span>
        </div>
        <div #studentList class="student-container scroll">
            <div class="row" *ngFor="let student of studentMarks; let i = index">
                <p>{{ student.first_name }} {{ student.last_name }}</p>
                <p>{{ student.renderedMark ? student.renderedMark : student.received_mark }}</p>
            </div>
        </div>
        <div class="flex-row buttons">
            <button [class.selected]="filterMode === 'letter'" (click)="switchMode('letter')">A+</button>
            <button [class.selected]="filterMode === 'percent'" (click)="switchMode('percent')">%</button>
            <button [class.selected]="filterMode === 'fraction'" (click)="switchMode('fraction')">¼</button>
        </div>
    </div>
    <mat-spinner class="center" *ngIf="loadingMarks" diameter="40"></mat-spinner>
</div>