import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataStoreService } from 'src/app/services/data-store.service';
import { ExpandableCardsService } from 'src/app/services/expandable-cards.service';

@Component({
  selector: 'expandable-card',
  templateUrl: './expandable-card.component.html'
})
export class ExpandableCardComponent {
  @Input() active = false;
  @Input() locked = false;
  @Input() lockedMessage = '';
  @Input() cardName = ''

  activeCard = false;

  @Output() setActive = new EventEmitter<boolean>();

  constructor(public cardsService: ExpandableCardsService, public ds: DataStoreService) {}

  toggleCard() {
    if (!this.locked) {
      this.cardsService.setActiveCard(this.cardName)
    }
  }
}
