    

    <div class="white-container center">
      <div class="main-div">
        <img src="assets/jamea-logo.svg" alt="Jamea Logo" class="logo" />
        <div id="login-form" class="">
          <div *ngIf="loginRetry" class="error-banner">Login Failed. Please try again.</div>  
            <form class="">
              <div class="input">
                <i class="material-icons">email</i>
                <input
                  #username
                  type="email"
                  placeholder="email"
                  class=""
                  (keyup.enter)="login(username.value, password.value)"
                />
              </div>
              <div class="input password">
                <i class="material-icons">lock</i>
                <input
                  #password
                  type="password"
                  placeholder="password"
                  class=""
                  (keyup.enter)="login(username.value, password.value)"
                />
                <p [routerLink]="'/' + appRoutes.FORGOT_PASSWORD">Forgot password?</p>
              </div>
              <div class="button-container">
                <jamea-button
                  color="blue"
                  [loading]="loggingIn"
                  width="158px"
                  height="55px"
                  (click)="login(username.value, password.value)">
                    Log in
                </jamea-button>
              </div>
              <div class="link-container">
                <a
                class="link-button"
                routerLink="/sign-up"
                >Sign up</a
                >
              </div>
            </form>
          </div>
      </div>
    </div>