import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[centerElement]'
})
export class CenterDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    const parent = (this.el.nativeElement as HTMLElement).parentElement;
    this.renderer.setStyle(parent, "position", "relative")
    this.renderer.setStyle(this.el.nativeElement, "position", "absolute")
    this.renderer.setStyle(this.el.nativeElement, "left", "50%")
    this.renderer.setStyle(this.el.nativeElement, "margin-left", (-1 * this.el.nativeElement.clientWidth / 2) + "px");
  }

}
