import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { Subject, filter, takeUntil, take, switchMap } from 'rxjs';
import { DataStoreService } from 'src/app/services/data-store.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ExpandableCardComponent } from '../../shared/expandable-cards/expandable-card/expandable-card.component';
import { RestApiService } from 'src/app/services/rest-api.service';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Course } from 'src/models/courses';
import { MatDialog } from '@angular/material/dialog';
import { ExportDialogComponent } from './export-dialog/export-dialog.component';

interface PastReports {
  file_name: string;
  pre_signed_url: string;
}

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  activeCourseName: string;
  courses: Course[] = [];
  destroy$ = new Subject();

  monthlyView = true;
  semesterView = false;
  pastReports: PastReports[];
  loadingReports = false;

  reportGenerationForm: FormGroup;

  active = false;
  lockedMessage = ''
  locked = false;

  studentDropdownOpen = false;
  commentDropdownOpen = false;
  addCommentsToggle = false;

  students;
  selectedStudents = [];
  selectedStudentsFC = new FormControl(this.selectedStudents);
  currentStudent;
  commentedStudents = [];
  comments = [];
  currentComment = '';
  reportType ='progress';

  dateRange = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  commentGroup = new FormGroup({
    currentComment: new FormControl(this.currentComment)
  });

  @ViewChild('commentDropdown') commentDropdown: ElementRef;
  @ViewChild('studentDropdown') studentDropdown: ElementRef;

  constructor(public ds: DataStoreService, public config: AppConfigService, private restApi: RestApiService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.ds.activeCourseName$.pipe(
      filter(course => !!course),
      takeUntil(this.destroy$)
    ).subscribe((courseName: string) => {
      this.activeCourseName = courseName;
      this.getStudents();
    });
    
    this.getPastReports();
  }

  getStudents() {
    this.restApi.getStudentListByActiveCourse()
    .subscribe(res => {
      this.students = res;
    });
  }

  toggleCard() {}

  selectedStudentsText() {
    if (!this.students) {
      return 'No students available!'
    }

    return this.selectedStudents.length == 0 ? 
    'Select students' : 
    (this.selectedStudents[0].first_name + ' ' + this.selectedStudents[0].last_name) + (this.selectedStudents.length > 1 ? " (+" + (this.selectedStudents.length == 2 ? this.selectedStudents.length - 1 + " other)" : this.selectedStudents.length - 1 + " others)") : "")
  }

  toggleStudentDropdown() {
    this.studentDropdownOpen = !this.studentDropdownOpen;
  }

  toggleCommentDropdown() {
    this.commentDropdownOpen = !this.commentDropdownOpen;
  }

  closeCommentDropdown() {
    this.commentDropdownOpen = false;
  }

  openStudentDropdown() {
   setTimeout(()=>this.studentDropdownOpen = true)
  }

  closeStudentDropdown() {
    this.studentDropdownOpen = false;
  }

  toggleAddComment() {
    this.addCommentsToggle = !this.addCommentsToggle;
  }
  
  selectStudentForComment(student) {
    this.currentStudent = student;
    this.commentDropdownOpen = false;
    this.findPreviousComment();
  }

  markStudentCommented(student, comment) {
    if (!this.commentedStudents.includes(student)) {
      this.commentedStudents.push(student);
      this.comments.push({});
      this.comments[this.comments.length - 1] = {id: student.id, comment: comment};
    } else {
      var findPreviousComment = this.comments.find(x => x.id === this.currentStudent.id);
      findPreviousComment.comment = comment;
    }
  }

  findPreviousComment() {
    var findPreviousComment = this.comments.find(x => x.id == this.currentStudent.id);
    this.currentComment = findPreviousComment == undefined ? '' : findPreviousComment.comment;
    this.commentGroup.controls.currentComment.setValue(this.currentComment);
  }

  notSelectedEndDate() {
    return this.dateRange.value.start != null && this.dateRange.value.end == null;
  }

  selectedDatesInFuture() {
    return (this.dateRange.value.start > new Date()) || (this.dateRange.value.end > new Date());
  }

  selectAllStudents() {
    if (this.students) {
      if (this.selectedStudents.length < this.students.length) {
        this.selectedStudents = [];
        this.students.forEach(element => {
          this.selectedStudents.push(element);
        });
        if (this.selectedStudents.length > 1 && this.currentStudent == undefined) {
          this.currentStudent = this.selectedStudents[0];
        }
        this.selectedStudentsFC = new FormControl(this.selectedStudents);
      } else if (this.selectedStudents.length == this.students.length) {
        this.selectedStudents = [];
        this.currentStudent = undefined;
        this.commentDropdownOpen = false;
        this.addCommentsToggle = false;
        this.selectedStudentsFC = new FormControl(this.selectedStudents);
      }
    }
  }

  nextStudent() {
    var nextIndex = this.selectedStudents.indexOf(this.currentStudent) + 1;
    if (nextIndex == this.selectedStudents.length) {
      nextIndex = 0;
    }
    this.currentStudent = this.selectedStudents[nextIndex];
    
    this.findPreviousComment();
  }

  previousStudent() {
    var previousIndex = this.selectedStudents.indexOf(this.currentStudent) - 1;
    if (previousIndex == -1) {
      previousIndex = this.selectedStudents.length - 1;
    }
    this.currentStudent = this.selectedStudents[previousIndex];

    this.findPreviousComment();
  }

  unselectStudent(student) {
    if (this.currentStudent == student) {
      this.currentStudent = this.selectedStudents[this.selectedStudents.indexOf(student) + 1]
    }

    this.selectedStudents.splice(this.selectedStudents.indexOf(student), 1);

    if (this.selectedStudents.length == 1) {
      this.currentStudent = this.selectedStudents[0];
    } else if (this.selectedStudents.length == 0) {
      this.currentStudent = undefined;
    }
  }

  selectStudent(student) {
    this.selectedStudents.push(student);
    if (this.selectedStudents.length == 1){
      this.currentStudent = this.selectedStudents[0];
    }
  }

  toggleView(view: string) {
    if (view === 'monthly') {
      this.semesterView = false;
      this.monthlyView = true;
      this.reportType = 'progress';
    } else if (view === 'semester') {
      this.monthlyView = false;
      this.semesterView = true;
      this.reportType = 'semester';
    }
  }

  toggleCheckmarkHierarchy(type, checkbox) {
    const formControls: any = this.reportGenerationForm.controls;
    let relevantControls;
    if (type === 'assessments') {
      relevantControls = ['assessments', 'total_grade', 'category_grades', 'all_grades'];
    } else if (type === 'attendance') {
      relevantControls = ['attendance', 'total_absents_and_lates', 'specific_dates'];
    }

    if (checkbox === relevantControls[0]) {
      if (formControls[checkbox].value === true) {
        formControls[relevantControls[1]].setValue(true);
      } else {
        relevantControls.forEach(control => formControls[control].setValue(false));
      }
    } else if (relevantControls.includes(checkbox)) {
      const checkboxIndex = relevantControls.indexOf(checkbox);
      if (formControls[checkbox].value === true) {
        const checkboxesToMark = relevantControls.slice(0, checkboxIndex);

        checkboxesToMark.forEach(checkbox => formControls[checkbox].setValue(true));
      } else if (formControls[checkbox].value === false) {
        
        const checkboxesToUnmark = relevantControls.slice(checkboxIndex)
        if (checkboxIndex === 1) {
          checkboxesToUnmark.push(relevantControls[0]);
        }
        checkboxesToUnmark.forEach(checkbox => formControls[checkbox].setValue(false));

      }
    }
  }

  enableSubmit() {
    return !this.notSelectedEndDate() 
    && this.dateRange.value.start != null 
    && this.dateRange.value.end != null 
    && this.selectedStudents.length > 0;
  }

  toggleAllClasses() {
    const controls = [];
    this.courses.forEach(course => {
      controls.push('course' + course.id)
    });

    const newValue = this.reportGenerationForm.controls['all_classes'].value === true ? true : false;
    controls.forEach(control => this.reportGenerationForm.controls[control].setValue(newValue));
  }

  getPastReports() {
    this.loadingReports = true;
    this.restApi.getPastReports().subscribe((res: PastReports[]) => {
      this.pastReports = res;
      this.loadingReports = false;
    })
  }

  exportStudentNames() {
    var res = '';
    this.selectedStudents.forEach(student => {
      if (this.selectedStudents.indexOf(student) < 9 &&  this.selectedStudents.indexOf(student) + 1 < this.selectedStudents.length) {
        res += student.first_name + " " + student.last_name + ", ";
      } else if (this.selectedStudents.indexOf(student) == 9 || this.selectedStudents.length == this.selectedStudents.indexOf(student) + 1) {
        res += student.first_name + " " + student.last_name;
      }
    });

    if (this.selectedStudents.length > 10) {
      res += " (+" + (this.selectedStudents.length - 10) + " others)";
    }

    return res;
  }

  exportCommentsMap() {
    var commentsMap = new Map();

    this.comments.forEach(c => {
        commentsMap[c.id.toString()] = c.comment;
    });

    return commentsMap;
  }
  openExportDialog() {

    const req = {
      type: this.reportType,
      schoolCode: this.ds.schoolCode.value,
      startDate: this.dateRange.value.start.toISOString().split('T')[0],
      endDate: this.dateRange.value.end.toISOString().split('T')[0],
      studentIds: this.selectedStudents.map((s) => s.id),
      studentNames: this.exportStudentNames(),
      finalComments: this.exportCommentsMap()
    }

    this.dialog.open(ExportDialogComponent, {
      width: '945px',
      height: '560px',
      panelClass: 'main-dialog',
      data: req,
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }
}