import { Injectable } from '@angular/core';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { DataStoreService } from './data-store.service';
import { Router } from '@angular/router';

export const Roles = {
  admin: 'admin',
  teacher: 'teacher'
}

export interface TokenDetails {
  AccessToken: string;
  ExpiresIn: string;
  IdToken: string;
  RefreshToken: string;
  TokenType: string;
  ChallengeName?: string;
  Session?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  cognitoUser: CognitoUser = null;
  roles: string[] = [];
  authKey = '';
  token = null;
  accessToken = null;
  isAdmin = false;
  isTeacher = false;
  teacherId = '';
  schoolCode = '';

  constructor(private ds: DataStoreService, private router: Router) {
    this.getTokenFromStorage();
  }

  storeToken(tokenDetails: TokenDetails) {
    localStorage.setItem('AccessToken', tokenDetails.AccessToken);
    localStorage.setItem('IdToken', tokenDetails.IdToken);
    localStorage.setItem('RefreshToken', tokenDetails.RefreshToken);
    localStorage.setItem('ExpiresIn', tokenDetails.ExpiresIn);
    localStorage.setItem('TokenType', tokenDetails.TokenType);
  }

  getTokenFromStorage(navigateTo?: string) {
    this.getTokenDetails(navigateTo);
  }

  getTokenDetails(navigateTo?: string) {
    if (localStorage.getItem('AccessToken') !== "undefined" && localStorage.getItem('AccessToken')) {
      this.accessToken = localStorage.getItem('AccessToken');
      this.token = this.decodeToken(localStorage.getItem('IdToken'));

      this.roles = this.token['cognito:groups'];
      if (!this.roles) {
        this.removeToken();
        return;
      }

      this.isAdmin = this.roles.includes('admin');
      this.isTeacher = this.roles.includes('teacher');

      this.schoolCode = this.token['custom:schoolCode'];
      this.ds.setSchoolCode(this.schoolCode);
      
      this.teacherId = this.token['custom:teacherId'];
      this.ds.setTeacherId(this.teacherId);

      if (navigateTo) {
        this.router.navigate([navigateTo])
      }
    }
  }

  removeToken() {
    this.token = null;
    this.accessToken = null;

    localStorage.removeItem('AccessToken');
    localStorage.removeItem('IdToken');
    localStorage.removeItem('RefreshToken');
    localStorage.removeItem('ExpiresIn');
    localStorage.removeItem('TokenType');
  }

  getUsername() {
    return this.token['given_name'] + ' ' + this.token['family_name'];
  }

  getCognitoUserFromUsername(username: string) {
    const userPool = new CognitoUserPool({
      UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
      ClientId: environment.cognitoAppClientId // Your client id here
    })

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool
    });

    return cognitoUser;
  }

  decodeToken(token) {
    return jwt_decode(token);
  }
}

