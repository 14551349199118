import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/app-routing.module';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DataStoreService } from 'src/app/services/data-store.service';

@Component({
  selector: 'app-class-details',
  templateUrl: './class-details.component.html',
  styleUrls: ['./class-details.component.scss']
})
export class ClassDetailsComponent implements OnInit {
  activeComponents: any = {
    attendance: false,
    assessments: false,
    reports: false
  }

  lockAttendance = false;

  constructor(public ds: DataStoreService, public config: AppConfigService, private router: Router, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      if (params['activeCard']) {
        this.setActive(params['activeCard'], true)
      }
    })
  }

  ngOnInit(): void {
    this.checkWindowSize();
  }

  setActive(component: string, flag: boolean, permission = true) {
    if (permission) {
      for (const key in this.activeComponents) {
        if (key === component) {
          this.activeComponents[key] = flag;
          const url = this.router.url;
          const sectionId = url.split('sectionId=')[1];
          this.router.navigate([APP_ROUTES.COURSE], { queryParamsHandling: 'merge', queryParams: { 'activeCard': component } });
        } else {
          this.activeComponents[key] = false;
        }
      }
    }
  }

  checkWindowSize() {
    if (window.innerWidth < 1160) {
      alert('This screen size is not supported!')
    }
  }

}
