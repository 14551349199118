import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './components/login/login.component';
import { AttendanceComponent } from './components/class-details/attendance/attendance.component';
import { LoaderComponent } from './components/loader/loader.component';
import { BannerComponent } from './components/banner/banner.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ClassDetailsComponent } from './components/class-details/class-details.component';
import { StudentDetailsComponent } from './components/student-details/student-details.component';
import { AssessmentsComponent } from './components/class-details/assessments/assessments.component';
import { CenterDirective } from './directives/center.directive';
import { AssessmentListComponent } from './components/class-details/assessments/assessment-list/assessment-list.component';
import { StudentMarksComponent } from './components/class-details/assessments/student-marks/student-marks.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AssessmentDialogComponent } from './components/class-details/assessments/assessment-dialog/assessment-dialog.component';
import { AuthenticationGuard } from './guards/authentication.guard';
import { ReportsComponent } from './components/class-details/reports/reports.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MarksDialogComponent } from './components/class-details/assessments/marks-dialog/marks-dialog.component';
import { HttpHeaderInterceptor } from './http-interceptor';
import { ConfirmationDialogComponent } from './components/shared/confirmation-dialog/confirmation-dialog.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { SuccessDialogComponent } from './components/shared/success-dialog/success-dialog.component';
import { ExpandableCardComponent } from './components/shared/expandable-cards/expandable-card/expandable-card.component';
import { ExportDialogComponent } from './components/class-details/reports/export-dialog/export-dialog.component';
import { ExpandableCardsComponent } from './components/shared/expandable-cards/expandable-cards.component';
import { ExpandableCardDirective } from './components/shared/expandable-cards/expandable-card.directive';
import { ConfirmCodeComponent } from './components/confirm-code/confirm-code.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { JameaButtonComponent } from './components/shared/jamea-button/jamea-button.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select'
import { MatCheckboxModule } from '@angular/material/checkbox'
import {ClickOutsideDirective} from './directives/clickoutside.directive';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AttendanceComponent,
    LoaderComponent,
    BannerComponent,
    SidebarComponent,
    SignUpComponent,
    ClassDetailsComponent,
    StudentDetailsComponent,
    AssessmentsComponent,
    CenterDirective,
    AssessmentListComponent,
    StudentMarksComponent,
    AssessmentDialogComponent,
    ReportsComponent,
    MarksDialogComponent,
    ConfirmationDialogComponent,
    ChangePasswordComponent,
    SuccessDialogComponent,
    ExpandableCardComponent,
    ExportDialogComponent,
    ExpandableCardsComponent,
    ExpandableCardDirective,
    ConfirmCodeComponent,
    ForgotPasswordComponent,
    JameaButtonComponent,
    ClickOutsideDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatMenuModule,
    MatDialogModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    TextFieldModule,
    FormsModule,
    MatSelectModule,
    MatCheckboxModule
  ],
  providers: [
    AuthenticationGuard,
    { provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
