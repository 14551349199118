import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';

@Injectable()
export class ExpandableCardsService {
  // activeCard = '';
  multipleActiveCards = false;

  activeCard = new BehaviorSubject('');
  activeCard$ = this.activeCard.asObservable();

  constructor() { }

  setActiveCard(componentName: string) {
    this.activeCard$.pipe(
      take(1)
    ).subscribe(activeCard => {
      if (activeCard !== componentName) {
        this.activeCard.next(componentName);
        return;
      }
        
      this.activeCard.next(null);
    })
  }
}
