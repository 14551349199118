import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import {BehaviorSubject, debounceTime, filter, take} from 'rxjs';
import { DataStoreService } from 'src/app/services/data-store.service';
import { RestApiService } from 'src/app/services/rest-api.service';
import { environment } from 'src/environments/environment';
import { AttendanceDataResponse } from 'src/models/attendance';
import { Course } from 'src/models/courses';
import jwt_decode from 'jwt-decode';
import * as dayjs from 'dayjs';
import { AppConfigService } from 'src/app/services/app-config.service';
import { APP_ROUTES } from 'src/app/app-routing.module';


@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() calendar = false;

  userName: string;
  schoolName: string;
  date: string = ''
  month = '';
  attendanceData: AttendanceDataResponse | any = null;

  dateChanged = new BehaviorSubject(false);
  @Output() toggleDates = new EventEmitter();

  constructor(public ds: DataStoreService,
              private restApi: RestApiService,
              private router: Router,
              public config: AppConfigService) { }

  ngOnInit(): void {    
    this.restApi.getSchool().subscribe(res => {
      this.schoolName = res[0].school_name;
    });


    this.date = dayjs().format('dddd MMMM D, YYYY');
    this.userName = this.config.getUsername();
  }

  onLogout(): void {
    this.config.removeToken();
    this.router.navigate([APP_ROUTES.LOGIN])
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch(Error) {
      return null;
    }
  }

}
