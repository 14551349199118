import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { APP_ROUTES } from 'src/app/app-routing.module';
import { AppConfigService, TokenDetails } from 'src/app/services/app-config.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { RestApiService } from 'src/app/services/rest-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginRetry = false;
  success = false;
  appRoutes = APP_ROUTES;
  loggingIn = false;

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private config: AppConfigService,
    private restApi: RestApiService) {

      if (this.config.token && !this.auth.isTokenExpired()) {
        this.router.navigate([APP_ROUTES.COURSE]);
      }
    }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params["retry"]) {
        this.loginRetry = true;
      }
    })
  }

  login(username: string, password: string) {
      this.loginRetry = false;
      this.loggingIn = true;

      this.restApi.login(username, password).subscribe({
        next: (res: TokenDetails) => {
          this.loggingIn = false;
          if (res.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
            this.router.navigate([APP_ROUTES.CHANGE_PASSWORD], { state: {
              email: username,
              session: res.Session
            }})

            return;
          }

          if (res.AccessToken) {
            this.success = true;
          }
          this.config.storeToken(res);
          this.config.getTokenFromStorage();
          this.router.navigate([APP_ROUTES.COURSE]);
          return;
        },
        error: err => {
          this.loginRetry = true;
          this.loggingIn = false;
        }
      });
  }
  
}
