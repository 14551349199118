<div class="main flex-row">
    <app-sidebar></app-sidebar>
    <div *ngIf="(ds.courses$ | async) || config.isAdmin" class="container">
        <app-banner></app-banner>
        <expandable-cards>
            <expandable-card *card="'Assessments'" cardName="Assessments">
                <assessments></assessments>
            </expandable-card>
            <expandable-card *card="'Attendance'" cardName="Attendance">
                <attendance></attendance>
            </expandable-card>
            <expandable-card *card="'Reports'" cardName="Reports">
                <reports></reports>
            </expandable-card>
        </expandable-cards>
    </div>
</div>

<div class="flex center not-supported">
    <span>This screen size is not currently supported!</span>
</div>
