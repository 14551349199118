<div class="white-container center" [class.reset-password]="resetPassword">
    <form [formGroup]="form" class="main-div">
        <img src="assets/jamea-logo.svg" alt="Jamea Logo" class="logo" />
        <span>Please enter the confirmation code sent to your email.</span>
        <div *ngIf="codeResent" class="success-banner">Code has been resent successfully!</div>  
        <span *ngIf="error" class="error">{{ error }}</span>
        <div class="flex-column">
            <label for="code">Confirmation Code</label>
            <input formControlName="confirmationCode" (keyup.enter)="submit()" name="code" id="code">
        </div>
        <div *ngIf="resetPassword" class="flex-column">
            <label for="password">New password</label>
            <input type="password" formControlName="password" (keyup.enter)="submit()" name="password" id="password">
        </div>
        <div *ngIf="resetPassword" class="flex-column">
            <label for="confirmPassword">Retype password</label>
            <input type="password" formControlName="confirmPassword" (keyup.enter)="submit()" name="confirmPassword" id="confirmPassword">
            <span *ngIf="form.get('confirmPassword').touched && 
                form.get('password').value !== form.get('confirmPassword').value" class="error validation-error">
                Passwords must match!
            </span>
        </div>
        <div class="button-container">
            <button [disabled]="form.invalid || form.get('password')?.value !== form.get('confirmPassword')?.value" class="button button-blue" (click)="submit()">Submit</button>
            <div class="link-container">
                <button
                class="link-button"
                (click)="resendCode()"
                [disabled]="!!(disableResendButtonUntil$ | async)">
                    Resend Code
                </button>
                <p *ngIf="(disableResendButtonUntil$ | async) as timer">You can send code again in {{ timer }} seconds</p>
              </div>
        </div>
    </form>
</div>