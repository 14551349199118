import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
// import * as moment from 'moment';
import { filter, switchMap, take, takeUntil } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { RestApiService } from 'src/app/services/rest-api.service';
import { Course } from 'src/models/courses';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { APP_ROUTES } from 'src/app/app-routing.module';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  courses: Course[];
  showAll = false;
  coursesLoaded = false;
  studentsLoaded = false;

  // today = moment().format('ddd').toLowerCase();
  showSidebar = true;
  menuOpen = false;
  sidebarWidth = 260;

  activeCourseId: string = null;
  activeCourseName: string;
  students: { first_name: string, last_name: string }[];

  @ViewChild('container') container;
  @ViewChild('classList') classList;
  @ViewChild('studentList') studentList;

  constructor(public dialog: MatDialog,
              public ds: DataStoreService,
              private router: Router,
              private route: ActivatedRoute,
              private restApi: RestApiService,
              public config: AppConfigService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.checkWindowWidth();
    this.getCourses();
    this.getStudents();

    this.ds.courses$.pipe(
      filter(courses => !!courses)
    ).subscribe((courses: Course[]) => {
      this.courses = courses;
      this.setMaxListHeights();
    })


    this.ds.activeCourse$.pipe(
      filter(course => !!course)
    ).subscribe(course => {
      this.activeCourseId = course.id;
    })

    this.ds.activeCourseName$.pipe(
      filter(course => !!course)
    ).subscribe((courseName: string) => {
      this.activeCourseName = courseName;
    })

  }

  clickEvent(e) {
    if (this.menuOpen && e.clientX > this.sidebarWidth) {
      this.closeMenu();
    }
  }

  openMenu() {
    this.menuOpen = true;
  }

  closeMenu() {
    this.menuOpen = false;
  }

  checkWindowWidth() {
    const width = window.innerWidth;
    if (width > 1350) {
      this.showSidebar = true;
    } else {
      this.showSidebar = false;
    }
  }

  onWindowResize() {
    this.checkWindowWidth();
    this.setMaxListHeights();
  }

  setMaxListHeights() {
    // reset the max heights so that our classList height is true to its actual value
    setTimeout(() => {
      this.renderer.setStyle(this.classList.nativeElement, 'max-height', '100vh');
      this.renderer.setStyle(this.studentList.nativeElement, 'max-height', '100vh');
      setTimeout(() => {
        const containerHeight = this.container.nativeElement.clientHeight;
        const logoHeight = 250;
        const paddingBottom = 20;
        const classListMaxHeight = (containerHeight - logoHeight - paddingBottom) / 2;
        const classListHeight = this.classList.nativeElement.clientHeight;
        // we want to set up the student list to take up the rest of the space in the sidebar if it needs
        // so if class list only needs x amount of space (i.e. classListHeight which is the current height)
        // then we can use that to determine the leftover space
        // otherwise, if class list needs more space than the allowed max (i.e. has its own scrollbar)
        // we will determine remaining space by using the max height of class list
        const studentListMaxHeight = classListHeight > classListMaxHeight ?
        containerHeight - logoHeight - classListMaxHeight : containerHeight - logoHeight - classListHeight;
        this.renderer.setStyle(this.classList.nativeElement, 'max-height', classListMaxHeight + 'px');
        this.renderer.setStyle(this.studentList.nativeElement, 'max-height', studentListMaxHeight + 'px');
      })
    })
  }

  getCourses() {
    this.restApi.getCourseList().pipe(
      switchMap(courses => {
        this.ds.setCourses(courses);
        return this.route.queryParams;
      }),
      take(1)
      ).subscribe(params => {
        this.coursesLoaded = true;
        if (!this.courses.length) return;

        if (!params["sectionId"] || !this.courses.find(course => course.id === params["sectionId"])) {
          this.selectCourse(this.courses[0].id);
        } else {
          this.ds.setActiveCourse(this.courses.find(course => course.id === params["sectionId"]))
        }
      }, error => {
        this.coursesLoaded = true;
      })
  }

  getStudents() {
    this.ds.activeCourse$.pipe(
      filter(course => !!course),
      switchMap(course => {
        return this.restApi.getStudentListBySection(course.id)
      })
    ).subscribe({
      next: (res) => {
        this.studentsLoaded = true;
        this.ds.setStudents(res);
      }, 
      error: () => {
        this.studentsLoaded = true;
      }
    })
  }

  selectCourse(sectionId: string) {
    this.ds.setActiveCourse(this.courses.find(course => course.id === sectionId));
    this.router.navigate([APP_ROUTES.COURSE], { queryParamsHandling: 'merge', queryParams: { sectionId } });
  }
}
