import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Course } from 'src/models/courses';
import {Student} from "../../models/student";

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
  courses = new BehaviorSubject<any>(null);
  courses$ = this.courses.asObservable();

  teacherId = new BehaviorSubject<any>(null);
  teacherId$ = this.teacherId.asObservable();
  
  schoolCode = new BehaviorSubject<any>(null);
  schoolCode$ = this.schoolCode.asObservable();

  activeCourse: BehaviorSubject<any> = new BehaviorSubject(null);
  activeCourse$: Observable<Course> = this.activeCourse.asObservable();

  activeCourseName: BehaviorSubject<any> = new BehaviorSubject(null);
  activeCourseName$: Observable<string> = this.activeCourseName.asObservable();

  students: BehaviorSubject<any> = new BehaviorSubject(null);
  students$: Observable<Student[]> = this.students.asObservable();

  fullStudentList = new BehaviorSubject(null);
  fullStudentList$ = this.fullStudentList.asObservable();

  attendance = new BehaviorSubject(null);
  attendance$ = this.attendance.asObservable();

  marks = new BehaviorSubject(null);
  marks$ = this.marks.asObservable();

  coursesLoaded = false;

  constructor() {
  }

  setTeacherId(id: string) {
    this.teacherId.next(id);
  }

  setSchoolCode(code: string) {
    this.schoolCode.next(code);
  }

  setActiveCourse(course: Course) {
    this.activeCourse.next(course);
    this.activeCourseName.next(course.course_title);
  }

  setCourses(courses: Course[]) {
    this.courses.next(courses);
    this.coursesLoaded = true;
  }
  setStudents(students: Student[]) {
    this.students.next(students);
  }

  setFullStudentsList(students: any) {
    this.fullStudentList.next(students);
  }

  setAttendance(attendance: any) {
    this.attendance.next(attendance);
  }
  setMarks(marks: any) {
    this.marks.next(marks);
  }
}
