import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil, debounceTime, filter } from 'rxjs';
import { CognitoUserPool,CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RestApiService } from 'src/app/services/rest-api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { APP_ROUTES } from 'src/app/app-routing.module';

interface formDataInterface {
  "given_name": string;
  "family_name": string;
  "gender": string;
  "email": string;
  "password": string;
  [key: string]: string;
};

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
  signUpForm: FormGroup;
  showPasswordError = false;

  showPassword = false;
  showConfirmPassword = false;

  correctSchoolCode = false;
  incorrectSchoolCode = false;
  codeCheckInProgress = false;
  
  emailExists = false;
  emailDoesNotExist = false;
  emailCheckInProgress = false;
  teacherId: string;

  disableForm = false;
  destroy$ = new Subject();

  error: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private restApi: RestApiService,
    private config: AppConfigService) { }

  ngOnInit(): void {
    this.signUpForm = this.fb.group({
      givenName: ['', Validators.required ],
      familyName: ['', Validators.required ],
      gender: ['', Validators.required ],
      schoolCode: ['', Validators.required ],
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      password: ['', [
        Validators.required,
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
      ]],
      confirmPassword: ['', [
        Validators.required,
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
      ]]
    });

    this.initFormListeners();
  }

  initFormListeners() {
    this.listenToSchoolCode();
    this.listenToEmail();
  }

  

  checkPasswordPattern() {
    if (this.signUpForm.get('password').errors && this.signUpForm.get('password').errors['pattern']) {
      this.showPasswordError = true;
    } else {
      this.showPasswordError = false;
    }
  }
  
  signUp() {
    // Call to create teacher in backend
    const formValues = this.signUpForm.value;
    const request = {
      email: formValues.email,
      first_name: formValues.givenName,
      last_name: formValues.familyName,
      gender: formValues.gender,
    }

    // Cognito related logic for sign up
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
      ClientId: environment.cognitoAppClientId // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    var attributeList = [];
    let formData: formDataInterface = {
      "given_name": formValues.givenName,
      "family_name": formValues.familyName,
      "gender": formValues.gender,
      "email": formValues.email,
      "password": formValues.password,
      "custom:schoolCode": formValues.schoolCode,
      "custom:teacherId": this.teacherId
    };

    for (let key in formData) {
      let attrData = {
        Name: key,
        Value: formData[key]
      }
      let attribute = new CognitoUserAttribute(attrData);
      if (key != 'password') {
        attributeList.push(attribute)
      }
    }
    const result = userPool.signUp(formValues.email, formValues.password, attributeList, [], (
      err,
      result
    ) => {
      if (err) {
        this.error = err.message || JSON.stringify(err);
        return;
      }
      this.config.cognitoUser = result.user;
      this.router.navigate([APP_ROUTES.CONFIRM_CODE]);
    });
   }

   toggleShowPassword() {
    this.showPassword = !this.showPassword;
   }

   toggleShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
   }

   listenToSchoolCode() {
    this.signUpForm.get('schoolCode').valueChanges.pipe(
      debounceTime(1000),
      filter(value => !!value),
      takeUntil(this.destroy$)
    ).subscribe(value => {
      this.correctSchoolCode = false;
      this.incorrectSchoolCode = false;
      this.codeCheckInProgress = true;
      this.restApi.validateSchoolCode(value).subscribe(res => {
        this.codeCheckInProgress = false
        if (res === 'True') {
          this.correctSchoolCode = true;
          this.incorrectSchoolCode = false;
          if (this.emailExists) this.disableForm = false;
        } else {
          this.disableForm = true;
          this.correctSchoolCode = false;
          this.incorrectSchoolCode = true;
        }
      })
    })

    this.signUpForm.get('schoolCode').valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.disableForm = true;
      this.correctSchoolCode = false;
      this.incorrectSchoolCode = false;
    })
  }

  listenToEmail() {
    this.signUpForm.get('email').valueChanges.pipe(
      debounceTime(1000),
      filter(value => !!value),
      takeUntil(this.destroy$)
    ).subscribe(value => {
      this.emailExists = false;
      this.emailDoesNotExist = false;
      this.emailCheckInProgress = true;
      this.restApi.checkForTeacherId(this.signUpForm.get('schoolCode').value, value).subscribe(res => {
        this.emailCheckInProgress = false
        if ((res as any).length) {
          this.teacherId = res[0].id;
          this.emailExists = true;
          this.emailDoesNotExist = false;
          if (this.correctSchoolCode) this.disableForm = false;
        } else {
          this.disableForm = true;
          this.emailExists = false;
          this.emailDoesNotExist = true;
        }
      })
    })

    this.signUpForm.get('email').valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.disableForm = true;
      this.emailExists = false;
      this.emailDoesNotExist = false;
    })
  }

   ngOnDestroy() {
    this.destroy$.next(null);
   }
}
