<div class="heading">
    <p class="input-heading">Type</p>
    <div class="input-div radio-div">
      <div class="input-div">
        <input type="radio" id="progress" name="report_type" (click)="toggleView('monthly')" checked>
        <label for="progress" class="input-label">Student Progress</label>
      </div>
      <div class="input-div ml-50" >
        <input type="radio" id="semester" name="report_type" (click)="toggleView('semester')">
        <label for="semester" class="input-label">Semester</label>
      </div>
    </div>
</div>
<div class="content">
  <div *ngIf="monthlyView" class="monthly-view">
    <form id="left-side">
      <div class="input-component">
        <p class="input-heading">Range</p>
          <mat-form-field class="date-range-form-field" (value)="dateRange">
            <div class="input-div date-range-input-div">
              <mat-date-range-input [rangePicker]="rangePicker" [formGroup]="dateRange">
                <input matStartDate formControlName="start">
                <input matEndDate formControlName="end">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
              <mat-date-range-picker #rangePicker></mat-date-range-picker>
            </div>
          </mat-form-field>
          <div class="error-div" *ngIf="notSelectedEndDate()">
            <p class="error-text">Please select both start and end dates for report range!</p>
          </div>
          <div class="error-div" *ngIf="selectedDatesInFuture()">
            <p class="error-text">Please select start and end dates that are not in the future!</p>
          </div>
      </div>

      <div class="input-component" >
        <p class="input-heading">Student(s)</p>
        <div class="input-div-dropdown" (clickOutside)="closeStudentDropdown()">
          <div class="input-select" (click)="toggleStudentDropdown()">
            <div class="input-div select-students-text">
              <img src="assets/icons/student_icon.svg" width="14px" >
              <p class="ml-5">{{selectedStudentsText()}}</p>
            </div>
            <img *ngIf="!studentDropdownOpen && students" src="assets/icons/dropdown-arrow.svg" id="open-student-dropdown" class="dropdown-arrow" (click)="openStudentDropdown()">
            <img *ngIf="studentDropdownOpen && students" src="assets/icons/dropdown-arrow.svg" class="dropdown-arrow reverse-arrow">
          </div>
          <div *ngIf="studentDropdownOpen && students" class="dropdown-list">
            <div class="input-div-row" *ngIf="students">
              <input type="checkbox" id="student-checkbox-all" [checked]="students && selectedStudents && selectedStudents.length == students.length" (click)="selectAllStudents()">
              <label for="student-checkbox-all">All students</label>
            </div>
            <div class="input-div-row" *ngFor="let student of students">
              <input type="checkbox" id="student-checkbox-{{student.id}}" [checked]="selectedStudents.includes(student)" (click)="selectedStudents.includes(student) ? unselectStudent(student) : selectStudent(student)">
              <label for="student-checkbox-{{student}}" (click)="selectedStudents.includes(student) ? unselectStudent(student) : selectStudent(student)">{{ student.first_name }} {{ student.last_name }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="input-component fixed-width">
        <div class="input-div-row-sb"> 
          <p class="input-heading">Email Guardian</p>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
      </div>

      <div class="input-component fixed-width">
        <div class="input-div-row-sb"> 
          <p class="input-heading">Add comment</p>
          <mat-slide-toggle [disabled]="selectedStudents.length == 0" [checked]="addCommentsToggle" (change)="toggleAddComment()"></mat-slide-toggle>
        </div>
      </div>

      <div class="input-component" *ngIf="addCommentsToggle">
        <div class="full-height input-component-column" > 
          <div class="input-div-row-sb" (clickOutside)="closeCommentDropdown()">
            <img src="assets/icons/dropdown-arrow.svg" class="dropdown-arrow arrow-left" (click)="previousStudent()">
            <div class="hover-background" (click)="toggleCommentDropdown()" #commentDropdown (blur)="closeCommentDropdown()">
              <p class="select-text">{{ currentStudent == undefined ? "Select students" : (currentStudent.first_name + ' ' + currentStudent.last_name) }}</p>
            </div>
            <img src="assets/icons/dropdown-arrow.svg" class="dropdown-arrow arrow-right" (click)="nextStudent()">
          </div>
          <div *ngIf="commentDropdownOpen" class="input-div-dropdown-comment">
            <div class="dropdown-list-comment">
              <div class="comment-div-row-sb" *ngFor="let student of selectedStudents" (click)="selectStudentForComment(student)">
                <p [class]="commentedStudents.includes(student) ? 'student-name-commented' : 'student-name'" >{{ student.first_name }} {{ student.last_name }}</p>
                <img *ngIf="commentedStudents.includes(student)" src="assets/icons/checkmark.svg" >
              </div>
            </div>
          </div>
        </div>
        
        <div [formGroup]="commentGroup">
          <textarea matInput class="textarea-style" (change)="markStudentCommented(currentStudent, comment.value)" formControlName="currentComment" name="comment" #comment></textarea>
        </div>
      </div>

      <div class="button-container">
        <button class="button button-blue" [disabled]="!enableSubmit()" (click)="openExportDialog()">Create +</button>
      </div>
    </form>
    <div id="right-side">
      <section>
        <h2>Past Reports</h2>
        <div class="past-reports-list scroll">
          <mat-spinner *ngIf="loadingReports" class="center" diameter="40"></mat-spinner>
          <div class="links" *ngFor="let report of pastReports">
            <a [href]="report.pre_signed_url">{{ report.file_name }}<img src="assets/icons/download_icon.svg"></a>
          </div>
        </div>
      </section>
    </div>
  </div>

  <div *ngIf="semesterView" class="semester-view">
    Semester View
  </div>
</div>
