import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import jwt_decode from 'jwt-decode';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
    isLoggedIn = false;
    accessToken = '';
    
    constructor(private config: AppConfigService) {}

    login() {
        let isAuth = false;

        const token = this.config.token;
        this.accessToken = this.config.accessToken;

        if (!token) {
            return false;
        }

        isAuth = !this.isTokenExpired();

        this.isLoggedIn = isAuth;
        return isAuth;
    }

    isTokenExpired() {
        const expiry = new Date(this.config.token.exp * 1000);
        return new Date().getTime() > expiry.getTime();
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch(Error) {
            return null;
        }
    }

    
}