
<div class="white-container center">
    <div class="main-div">
        <img src="assets/jamea-logo.svg" alt="Jamea Logo" class="logo" />
        <span>Please enter a new password for your account.</span>
        <span *ngIf="error" class="error">{{ error }}</span>
        <div class="flex-column">
            <label for="password">New Password</label>
            <input [type]="showPassword ? 'text' : 'password'" (keyup.enter)="submit(pw.value, confirmPW.value)" #pw name="password" id="password">
            <mat-icon (click)="toggleShowPassword()">{{ showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </div>
        <div class="flex-column">
            <label for="confirm-password">Confirm New Password</label>
            <input [type]="showConfirmPassword ? 'text' : 'password'" (keyup.enter)="submit(pw.value, confirmPW.value)" #confirmPW name="confirm-password" id="confirm-password">
            <mat-icon (click)="toggleShowConfirmPassword()">{{ showConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </div>
        <span *ngIf="confirmPW.value && pw.value !== confirmPW.value" class="error">
            Passwords must match!
        </span>
        <button class="button button-blue" (click)="submit(pw.value, confirmPW.value)">Submit</button>
    </div>
</div>