import { Component, OnInit, OnDestroy } from '@angular/core';
import * as dayjs from 'dayjs';
import { DataStoreService } from 'src/app/services/data-store.service';
import { RestApiService } from 'src/app/services/rest-api.service';
import { Subject, filter, takeUntil } from 'rxjs';
import { Course } from 'src/models/courses';
import { ExpandableCardComponent } from '../../shared/expandable-cards/expandable-card/expandable-card.component';
import { ExpandableCardsService } from 'src/app/services/expandable-cards.service';

export interface Assessment {
  id: number;
  title: string;
  date: string;
  category?: string;
  weight: string;
  class_average: string;
  total_mark: string;
}

@Component({
  selector: 'assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.scss']
})
export class AssessmentsComponent implements OnInit, OnDestroy {
  initialLoad = true;
  active = false;
  lockedMessage = ''
  locked = false;

  activeCourseName: string;
  activeCourse: string;
  activeAssessment: number;
  studentMarks: any;
  loadingAssessments = false;
  loadingMarks = false;

  assessmentsList!: Assessment[];

  destroy$ = new Subject();

  constructor(private restApi: RestApiService, private ds: DataStoreService, private cardsService: ExpandableCardsService) {
  }

  ngOnInit(): void {
    this.getData();

    this.ds.activeCourse$.pipe(
      filter(course => !!course),
      takeUntil(this.destroy$)
    ).subscribe((course: Course) => {
      this.activeCourseName = course.course_title;
      this.activeCourse = course.id;
      this.initialLoad = true;
      this.getData();
    })
}

  toggleCard() {}

  showStudentMarks(index: number) {
    this.activeAssessment = index;
    this.studentMarks = null;
    if (index !== null) {
      this.loadingMarks = true;
      this.restApi.getAssessmentMarks(this.assessmentsList[index].id)
        .subscribe((res: any) => {
          if (res.length) {
            this.studentMarks = res;
          } else {
            this.getStudentList(this.assessmentsList[index]);
          }
          
          this.loadingMarks = false;
        }, error => {
          this.loadingMarks = false;
        });
    }
    
  }

  getStudentList(assessment: Assessment) {
    this.restApi.getStudentListBySection(this.activeCourse).subscribe(res => {
      this.studentMarks = res.map(student => {
        return {
          input: true,
          id: assessment.id,
          first_name: student.first_name,
          last_name: student.last_name,
          student_id: student.id,
          received_mark: '-',
          title: assessment.title,
          total_mark: assessment.total_mark
        }
      })
    });
  }

  getData() {
    this.loadingAssessments = true;
    this.studentMarks = null;
    this.assessmentsList = [];
    this.restApi.getAssessmentList().subscribe((res) => {
      res.map(assessment => {
        this.loadingAssessments = false;
        assessment.date = dayjs(assessment.date).format('MMM D, YYYY');
        assessment.category = assessment.category ? assessment.category?.charAt(0).toUpperCase() + assessment?.category.slice(1).toLowerCase() : '';
        assessment.weight = Math.round(+(assessment.weight)).toFixed(1).toString();
        assessment.class_average = Math.round(+(assessment.class_average)).toFixed(1).toString();
      })
      this.assessmentsList = res;
      if (this.assessmentsList.length > 0 && this.initialLoad) {
        this.initialLoad = false;
        this.showStudentMarks(0);
      } else {
        this.showStudentMarks(this.activeAssessment);
      }
    }, error => {
      this.loadingAssessments = false;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

}
