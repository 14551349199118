<button class="button"
    type="button"
    [class.button-blue]="color === 'blue'"
    [class.button-white]="color === 'white'"
    [class.button-orange]="color === 'orange'"
    [disabled]="disabled || loading"
    [style.width]="width"
    [style.height]="height">
        <ng-content *ngIf="!loading"></ng-content>
        <mat-spinner class="center" *ngIf="loading" diameter="30"></mat-spinner>
</button>