import { CanActivate, Router } from "@angular/router";
import { AuthenticationService } from "../services/authentication.service";
import { Injectable } from '@angular/core';
import { APP_ROUTES } from "../app-routing.module";

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private auth: AuthenticationService, private router: Router) {}

  canActivate(): boolean {
    if (this.auth.login()) {
        return true
    } else {
        this.router.navigate([APP_ROUTES.LOGIN], { queryParams: { retry: true }});
        return false;
    }

  }
}