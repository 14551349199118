<div *ngIf="(cardsService.activeCard$ | async) !== cardName" [matTooltip]="lockedMessage"
  [matTooltipDisabled]="!locked"
  matTooltipPosition="below"
  class="expandable-card-wrapper"
  [class.locked]="locked"
  (click)="toggleCard()">
  <div class="expandable-content-container">
    <div class="heading">
      <span>{{ cardName }}</span>
      <button class="button-light-blue">Expand
          <i
          class="material-icons"
          
          >expand_more</i
        >
      </button>
    </div>
  </div>
</div>


<div *ngIf="(cardsService.activeCard$ | async) === cardName">
  <div class="expandable-card-wrapper active">
    <div class="expandable-content-container">
      <div class="heading" (click)="toggleCard()">
        <span>{{ cardName }}</span>
        <span *ngIf="cardName != 'Reports'" centerElement class="course title-text" >
          {{ (ds.activeCourse$ | async)?.course_title }}
        </span>
      </div>
      <ng-content></ng-content>
    </div>
  </div>

</div>
