import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewContainerRef } from "@angular/core";
import { Subject, filter, takeUntil } from "rxjs";
import { ExpandableCardsService } from "src/app/services/expandable-cards.service";

@Directive({
    selector: "[card]"
  })
  export class ExpandableCardDirective implements OnInit, OnDestroy {
    @Input() card;
    destroy$ = new Subject();

    componentRef: TemplateRef<any>;

    constructor(private el: ElementRef, private renderer: Renderer2, private cardsService: ExpandableCardsService, private templateRef: TemplateRef<any>, private container: ViewContainerRef) {}

    ngOnInit() {
        // we are using this along with the ExpandableCardsComponent to dynamically render the cards in order
        this.componentRef = this.templateRef;
        this.container.createEmbeddedView(this.templateRef);
        this.cardsService.activeCard$.pipe(
            takeUntil(this.destroy$),
            filter(activeCard => !!activeCard)
        ).subscribe(activeCard => {
            // TODO refactor to avoid rendering already existing components again...
            this.container.clear();

            if (activeCard !== this.card) {
                this.container.createEmbeddedView(this.templateRef);
            }

        })
    }

     ngOnDestroy(): void {
         this.destroy$.next(true);
         this.destroy$.complete();
     }
  }